body {
  font-size: 1.5rem;
  line-height: normal;
  justify-content: flex-start;
}

.pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline {
  display: inline-block;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.flex-center,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.link {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.round {
  border-radius: 100%;
}

.transparent {
  color: transparent;
}

/* dialog */
ion-alert.large {
  --width: 400px;
  --max-width: 340px;
}

/* toaster */
ion-toast {
  max-width: 400px;
  margin: 0 auto;
  min-height: 64px;
  height: auto;
  position: relative;
}
ion-toast.small {
  max-width: 250px;
}
ion-toast.large {
  max-width: 600px;
}

.ion-overlay-wrapper {
  --min-width: 300px;
}

.readonly {
  pointer-events: none;
}

ion-item.readonly ion-select::part(icon) {
  display: none;
}

ion-content ion-item.item {
  ion-select,
  ion-datetime,
  ion-input {
    font-size: 1.4rem;
  }
  ion-label.hydrated {
    font-size: 1.4rem;
  }
}

ion-title {
  font-size: 18px;
  font-weight: 600;
}

textarea {
  min-height: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

svg.MuiSvgIcon-root {
  font-size: 2.4rem;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
  font-size: 3.5rem;
}

ion-modal {
  .modal-wrapper {
    // --background: rgb(0, 0, 0);
    // --color: #333;

    ion-content,
    ion-header,
    ion-footer,
    ion-toolbar {
      --background: #fff;
      --color: #333;
    }

    .MuiFormLabel-root {
      color: #333;
    }
  }
}

ion-loading {
  .loading-wrapper {
    .loading-spinner {
      color: #428cff;
      --spinner-color: #428cff;
      font-weight: 600;
    }
  }
}

ion-alert {
  .alert-wrapper {
    --background: #fff;
    --color: #000;

    .alert-title,
    .alert-message {
      color: #000;
    }
    .alert-button {
      color: #428cff;
      font-weight: 600;
    }
  }
}

.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 2.4rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.mui-dialog {
  .MuiDialog-paperWidthSm {
    max-width: 95%;
  }
  .MuiDialog-paper {
    margin: 0;
  }
}

/* MATERIAL DEFAULT */
.MuiSelect-select:focus {
  background-color: initial;
}
.MuiFormLabel-root {
  font-size: inherit;
}
.MuiInputAdornment-root ion-icon {
  font-size: 2rem;
}

.site-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px;
}

/* MATERIAL UI INPUT COLORS */
.MuiFormLabel-root {
  color: var(--ion-text-color);
}

.MuiButtonGroup-grouped {
  min-height: 40px;
  span {
    line-height: normal;
  }
}
.MuiInputBase-input.Mui-disabled {
  background-color: rgba(var(--ion-color-light-rgb), 0.2);
}

/* @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
  .can-go-back > ion-header ion-back-button {
    display: none !important;
  }
} */

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  display: flex;
  align-items: center;
}

// Disabled input
.MuiInputBase-root.Mui-disabled {
  opacity: 0.9;
  input {
    background-color: rgba(0, 0, 0, 0.13);
  }
}

.alert-message {
  color: var(--ion-text-color);
}

.warning.outlined {
  color: var(--ion-color-warning);
  border-color: var(--ion-color-warning);
}
.success.outlined {
  color: var(--ion-color-success);
  border-color: var(--ion-color-success);
}
.danger.outlined {
  color: var(--ion-color-danger);
  border-color: var(--ion-color-danger);
}
.primary.outlined {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}
.secondary.outlined {
  color: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
}
.tertiary.outlined {
  color: var(--ion-color-tertiary);
  border-color: var(--ion-color-tertiary);
}
.outlined.dark {
  color: var(--ion-color-dark);
  border-color: var(--ion-color-tertiary);
}
.outlined.light {
  color: var(--ion-color-light);
  border-color: var(--ion-color-light);
}
.warning.filled {
  color: var(--ion-color-warning-contrast-bw);
  background-color: var(--ion-color-warning);
}
.success.filled {
  color: var(--ion-color-success-contrast-bw);
  background-color: var(--ion-color-success);
}
.danger.filled {
  color: var(--ion-color-danger-contrast-bw);
  background-color: var(--ion-color-danger);
}
.primary.filled {
  color: var(--ion-color-primary-contrast-bw);
  background-color: var(--ion-color-primary);
}
.secondary.filled {
  color: var(--ion-color-secondary-contrast-bw);
  background-color: var(--ion-color-secondary);
}
.tertiary.filled {
  color: var(--ion-color-tertiary-contrast-bw);
  background-color: var(--ion-color-tertiary);
}
.dark.filled {
  color: var(--ion-color-dark-contrast-bw);
  background-color: var(--ion-color-dark);
}
.light.filled {
  color: var(--ion-color-light-contrast-bw);
  background-color: var(--ion-color-light);
}

.uppercase {
  text-transform: uppercase;
}
.uppercase-words {
  text-transform: capitalize;
}
.lowercase {
  text-transform: lowercase;
}
