html {
  font-size: 10px;
}

ion-footer ion-toolbar {
  --background: var(--ion-background-color);
  text-align: center;
}

ion-modal {
  .MuiInputBase-root {
    background-color: var(--ion-color-dark);
  }
  .MuiButton-contained {
    background-color: var(--ion-color-primary);
  }
  button.MuiButton-contained.Mui-disabled {
    color: var(--ion-color-primary-contrast);
    background-color: var(--ion-color-primary);
    opacity: 0.5;
  }
}

button.MuiButton-contained.Mui-disabled {
  opacity: 0.5;
}

ion-app ion-toast.hydrated {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}
